import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import styles from "./index.module.scss";
import { Layout } from "../components/Layout/Layout";
import { Hero } from "../components/Hero/Hero";
import { SEO } from "../components/SEO/SEO";

const IndexPage = () => (
  <Layout>
    <SEO title="Manchester-based lead software engineer" />
    <Hero
      heading="ediblecode / Ian Routledge"
      lead={<>Manchester-based lead software&nbsp;engineer</>}
    />

    <div className={styles.grid}>
      <section
        aria-labelledby="blog"
        className={classnames(styles.block, styles.blog)}
      >
        <h2 id="blog">
          <Link to="/blog/">Blog</Link>
        </h2>
        <p>Dev, tech and&nbsp;accessibility</p>
      </section>

      <section
        aria-labelledby="photos"
        className={classnames(styles.block, styles.photos)}
      >
        <h2 id="photos">
          <Link to="/photos/">Photos</Link>
        </h2>
        <p>Nature and landscape&nbsp;photography</p>
      </section>

      <section
        aria-labelledby="about"
        className={classnames(styles.block, styles.about)}
      >
        <h2 id="about">
          <Link to="/about/">About</Link>
        </h2>
        <p>Manchester-based lead software&nbsp;engineer</p>
      </section>

      <section
        aria-labelledby="connect"
        className={classnames(styles.block, styles.connect)}
      >
        <h2 id="connect">
          <Link to="/connect/">Connect</Link>
        </h2>
        <p>Get in touch and find me on social&nbsp;media</p>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
